import { navigate, RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractType, Equipment, Site } from '../../../interfaces/customer-api';
import InsightContract from '../../assets/contract-icons/insight.svg';
import LeasingContract from '../../assets/contract-icons/leasing.svg';
import MaintenanceContract from '../../assets/contract-icons/maintenance.svg';
import ShortTermRentalContract from '../../assets/contract-icons/short-term-rental.svg';
// import Checkmark from '../../assets/checkmark.svg';
import Search from '../../assets/search.svg';
import { useActions, useOvermindState } from '../../state';
import { cns, isFailed, isLoading } from '../../utils';
import { EquipmentIcon } from '../EquipmentIcon/EquipmentIcon';
import { Input } from '../Input/Input';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { Column, Table } from '../Table/Table';
import { Tooltip } from '../Tooltip/Tooltip';
import { withIsMobile, WithIsMobileProps } from '../withIsMobile/withIsMobile';
import css from './MyEquipmentTable.module.scss';
import { hasIconForEquipmentType } from '../../equipment-icons';

interface ContractIconsProps {
    contracts: ContractType[];
}
const ContractIcons: React.FC<ContractIconsProps> = ({ contracts }: ContractIconsProps) => {
    const { t } = useTranslation('MyEquipment');

    const contractElements = sortBy(contracts, (contract) => contract).map((contract) => {
        switch (contract) {
            case 'leasing':
                return (
                    <Tooltip key={contract} title={t<string>('Leasing')} placement="top">
                        <span className={css.contractIcon}>
                            <LeasingContract />
                        </span>
                    </Tooltip>
                );
            case 'maintenance':
                return (
                    <Tooltip key={contract} title={t<string>('Maintenance')} placement="top">
                        <span className={css.contractIcon}>
                            <MaintenanceContract />
                        </span>
                    </Tooltip>
                );
            case 'on-demand-maintenance':
                return (
                    <Tooltip key={contract} title={t<string>('On demand maintenance')} placement="top">
                        <span className={css.contractIcon}>
                            <MaintenanceContract />
                        </span>
                    </Tooltip>
                );
            case 'rental-short-term':
                return (
                    <Tooltip key={contract} title={t<string>('Short-term rental')} placement="top">
                        <span className={css.contractIcon}>
                            <ShortTermRentalContract />
                        </span>
                    </Tooltip>
                );
            default:
                return null;
        }
    });

    return <>{contractElements}</>;
};

type MyEquipmentTableProps = RouteComponentProps & WithIsMobileProps;

const filterEquipment = (
    equipment: Equipment,
    filterValue: string,
    t: TFunction,
    sitesByShipTo: Record<string, Site>,
): boolean => {
    const searchValues = [
        equipment.technical_identificationnumber,
        equipment.serial_number,
        equipment.type ? equipment.type : t('Other'),
        equipment.business_partner_sh,
        equipment.manufacturer_model_number ? equipment.manufacturer_model_number : t('Other'),
        sitesByShipTo[equipment.business_partner_sh]?.name ?? equipment.business_partner_sh,
        hasIconForEquipmentType(equipment.type) ? '' : t('Other Equipment'),
    ].map((value) => value.toLowerCase());

    return searchValues.some((value) => value.includes(filterValue.toLowerCase()));
};

const onRowClick = async (equ: Equipment) => {
    const equipmentId = equ.equipment_number;

    await navigate(`${window.location.pathname}/${equipmentId}`);
};

export const MyEquipmentTable: React.FC<MyEquipmentTableProps> = withIsMobile(({ isMobile }: MyEquipmentTableProps) => {
    const { t } = useTranslation('MyEquipment');
    const { siteEquipment, loadingStates, sitesByShipTo } = useOvermindState();

    const [filterValue, setFilterValue] = useState('');

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            // Check if url contains a search term on first render
            const query = queryString.parse(window.location.search);
            if (query && query.search) {
                setFilterValue(query.search as string);
            }
            isFirstRender.current = false;
        } else {
            const query = queryString.stringify({ search: filterValue });
            window.history.replaceState(null, '', `?${query}`);
        }
    }, [filterValue]);

    const columns: Column<Equipment>[] = compact([
        {
            title: '',
            accessor: ({ type }: Equipment) => <EquipmentIcon type={type} />,
            className: cns(css.centered, css.type),
        },
        {
            title: t('Equipment name'),
            accessor: (equ: Equipment) => {
                return equ.technical_identificationnumber;
            },

            className: css.name,
        },
        {
            title: t('Serial number'),
            accessor: (equ: Equipment) => {
                return equ.serial_number;
            },

            className: css.serialNum,
        },
        !isMobile && {
            title: t('Description'),
            accessor: ({ type }: Equipment) => (type ? type : t('Other')),
            className: css.description,
        },
        !isMobile && {
            title: t('Model'),
            accessor: ({ manufacturer_model_number }: Equipment) =>
                manufacturer_model_number ? manufacturer_model_number : t('Other'),
            className: css.model,
        },
        !isMobile && {
            title: t('Site'),
            accessor: (equ: Equipment) => {
                const shipTo = equ.business_partner_sh;
                return sitesByShipTo[shipTo]?.name ?? shipTo;
            },
            className: css.site,
        },
        !isMobile && {
            title: t('Contracts'),
            accessor: ({ contractTypeSet }: Equipment) => <ContractIcons contracts={contractTypeSet} />,
            className: css.contracts,
        },
        !isMobile && {
            title: t('Digital subscriptions'),
            accessor: ({ contractTypeSet }: Equipment) => {
                if (contractTypeSet.includes('insight')) {
                    return (
                        <Tooltip key={'insight'} title={t<string>('Insight')} placement="top">
                            <span className={css.contractIcon}>
                                <InsightContract />
                            </span>
                        </Tooltip>
                    );
                }
            },
            className: css.subscription,
        },
    ]);

    const { getEquipment } = useActions();
    if (isFailed(loadingStates.equipment)) {
        return <LoadingFailure translationKey="Loading equipment failed" retry={getEquipment} />;
    }

    return (
        <>
            <Input
                containerProps={{
                    className: css.searchField,
                }}
                icon={!isMobile ? <Search /> : undefined}
                id="my-equipment-search"
                inputProps={{
                    onChange: ({ target }) => setFilterValue(target.value),
                    placeholder: !isMobile
                        ? `${t('Search equipment with name, serial number, description, model or site')}...`
                        : t('Search with name, serial number or description'),
                    type: 'text',
                    value: filterValue,
                    className: css.searchFieldInput,
                }}
            />
            <Table
                columns={columns}
                data={siteEquipment.filter((equ) => filterEquipment(equ, filterValue, t, sitesByShipTo))}
                className={css.equipmentTable}
                onRowClick={onRowClick}
                id="my-equipment-table"
                isLoading={isLoading(loadingStates.equipment)}
            />
        </>
    );
});
